/* eslint-disable max-len */
import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { userGroup as g } from 'api/core/lib/user-group';

import { PrivateRoute } from './components/PrivateRoute';
import { useVersionCheck } from './lib/hooks/useVersionCheck';

export const IS_ENABLE_EXT_MANAGER = process.env.REACT_APP_ENABLE_EXT_MANAGER === 'true';

// const SuperAdminCreateUser = lazy(() => import('./screens/superAdmin/CreateUser'));

const SuperAdminSettings = lazy(() => import('./screens/superAdmin/Settings'));
const SuperAdminMission = lazy(() => import('./screens/admin/Mission'));
const SuperAdminSettingsPlace = lazy(() => import('./screens/superAdmin/Place'));
const SuperAdminSettingsPlaceEdit = lazy(() => import('./screens/superAdmin/PlaceEdit'));
const SuperAdminSettingsDomainEdit = lazy(() => import('./screens/superAdmin/DomainEdit'));
const SuperAdminSettingsParticipant = lazy(() => import('./screens/superAdmin/Participant'));
const SuperAdminSettingsDomain = lazy(() => import('./screens/superAdmin/Domain'));
const SuperAdminSettingsFormation = lazy(() => import('./screens/superAdmin/FormationType'));
const SuperAdminSettingsFormationEdit = lazy(() => import('./screens/superAdmin/FormationTypeEdit'));
const SuperAdminInterventionCreate = lazy(() => import('./screens/superAdmin/InterventionCreate'));

const AccountCreate = lazy(() => import('./screens/account/AccountCreate'));
const AccountList = lazy(() => import('./screens/account/AccountList'));
const AccountEdit = lazy(() => import('./screens/account/AccountEdit'));
const AccountProfile = lazy(() => import('./screens/account/AccountProfile'));

const ParticipantMission = lazy(() => import('./screens/participant/ParticipantMission'));
const ParticipantCreate = lazy(() => import('./screens/participant/ParticipantCreate'));
const ParticipantEdit = lazy(() => import('./screens/participant/ParticipantEdit'));

// const AccountAnimatorEdit = lazy(() => import('./screens/account/AccountAnimatorEdit'));

const AdminCalendar = lazy(() => import('./screens/admin/Calendar'));
const AdminAnimatorList = lazy(() => import('./screens/admin/FavoriteAnimatorsList'));
const AdminHome = lazy(() => import('./screens/admin/Mission'));
const AdminIntervention = lazy(() => import('./screens/admin/Intervention'));
const AdminInterventionEdit = lazy(() => import('./screens/admin/InterventionEdit'));
const AdminInterventionRead = lazy(() => import('./screens/admin/InterventionRead'));
// const AdminIntervention = lazy(() => import('./screens/admin/Intervention'));
// const AdminMissionRead = lazy(() => import('./screens/mission/Read'));
const AdminMissionCreate = lazy(() => import('./screens/mission/Create'));

//Statistics
const StatisticsMission = lazy(() => import('./screens/Stats/Stats'));

// DG
const DGCalendar = lazy(() => import('./screens/DG/Calendar'));
const DGInterventionCreate = lazy(() => import('./screens/DG/InterventionCreate'));
const DGInterventionRead = lazy(() => import('./screens/DG/InterventionRead'));
const DGInterventionEdit = lazy(() => import('./screens/DG/InterventionEdit'));
const DGInterventions = lazy(() => import('./screens/DG/Interventions'));
const DGMyInterventions = lazy(() => import('./screens/DG/MyInterventions'));
const DGMyDA = lazy(() => import('./screens/DG/MyDA'));

// DA
const DACalendar = lazy(() => import('./screens/DA/Calendar'));
const DAHome = lazy(() => import('./screens/DA/Home'));
const DAInterventionCreate = lazy(() => import('./screens/DA/InterventionCreate'));
const DAInterventionEdit = lazy(() => import('./screens/DA/InterventionEdit'));
const DAInterventionRead = lazy(() => import('./screens/DA/InterventionRead'));

// DC
const DCInterventionCreate = lazy(() => import('./screens/DC/InterventionCreate'));
// const DCMyInterventions = lazy(() => import('./screens/DC/MyInterventions'));
const DCInterventions = lazy(() => import('./screens/DC/Interventions'));
const DCInterventionRead = lazy(() => import('./screens/DC/InterventionRead'));
const DCInterventionEdit = lazy(() => import('./screens/DC/InterventionEdit'));

// ANIMATOR
const AnimatorCalendar = lazy(() => import('./screens/animator/Calendar'));
const AnimatorIntervention = lazy(() => import('./screens/animator/Intervention'));
const AnimatorInterventionRead = lazy(() => import('./screens/animator/InterventionRead'));
const AnimatorMission = lazy(() => import('./screens/animator/Mission'));

// MISSIONS COMMON
const MissionCreate = lazy(() => import('./screens/mission/Create'));
const MissionEdit = lazy(() => import('./screens/mission/Edit'));
const MissionRead = lazy(() => import('./screens/mission/Read'));

// COMMON PAGES PRIVATE PAGE
const PageChangeLog = lazy(() => import('./screens/ChangeLog'));
// COMMON PAGES
const DefaultLayout = lazy(() => import('./components/DefaultLayout/DefaultLayout'));
const PageAuthForgotPassword = lazy(() => import('./screens/Auth/ForgotPassword'));
const PageLogin = lazy(() => import('./screens/Auth/Login'));
const PageFinalize = lazy(() => import('./screens/Auth/Finalize'));
const PageForbidden = lazy(() => import('./screens/Auth/Forbidden'));
const PageNotFound = lazy(() => import('./screens/PageNotFound'));
const Loading = <div className="animated fadeIn pt-3 text-center">Chargement...</div>;

export const Routes = () => {
	useVersionCheck();
	return (
		<Suspense fallback={Loading}>
			<Switch>
				<Route path={[
					'/login',
					'/reset-password',
					'/forgot-password',
					'/finalize/:credentials',
					'/forbidden',
				]}
				>
					<Switch>
						<Route path="/login" name="Login" component={PageLogin} />
						<Route path="/forgot-password" name="Forgot" component={PageAuthForgotPassword} />
						<Route path="/finalize/:credentials" name="Reset" component={PageFinalize} />
						<Route path="/forbidden" component={PageForbidden} />
					</Switch>
				</Route>
				<Route>
					<DefaultLayout
						containerFluidPages={[
							'/admin/calendar',
							'/admin/settings/participant',
							'/calendar',
							'/DA/calendar',
							'/DC/calendar',
							'/DG/calendar',
							'/stats',
						]}
					>
						<Switch>
							{/* ANIMATOR */}
							<PrivateRoute path="/mission/create" exact component={MissionCreate} group={g.Animator} />
							<PrivateRoute path="/mission/create/from-intervention/:interventionId" exact component={MissionCreate} group={g.Animator} />
							<PrivateRoute path="/mission/create/from-following-mission/:followingMisionId" exact component={MissionCreate} group={g.Animator} />
							<PrivateRoute path="/mission/create/duplicate/:duplicateMissionId" exact component={MissionCreate} group={g.Animator} />
							<PrivateRoute path="/mission/cancel/:id" key="cancel" exact component={MissionRead} group={g.Animator} />
							<PrivateRoute path="/mission/edit/:id" exact component={MissionEdit} group={g.Animator} />
							<PrivateRoute path="/mission/closed/:id" exact component={MissionRead} group={g.Animator} />
							<PrivateRoute path="/mission/:id" key="read" exact component={MissionRead} group={g.Animator} />
							<PrivateRoute path="/mission" exact component={AnimatorMission} group={g.Animator} />
							<PrivateRoute path="/intervention" exact component={AnimatorIntervention} group={g.Animator} />
							<PrivateRoute path="/intervention/:id" exact component={AnimatorInterventionRead} group={g.Animator} />
							<PrivateRoute path="/participant/mission" component={ParticipantMission} group={g.Animator} />
							<PrivateRoute path="/profile" exact component={AccountProfile} group={g.Animator} />
							<PrivateRoute path="/calendar" exact component={AnimatorCalendar} group={g.Animator} />
							<PrivateRoute path="/missions" exact component={SuperAdminMission} group={g.AnimatorVision} />
							<PrivateRoute path="/" exact component={AnimatorMission} group={g.Animator} notGroup={g.AnimatorVision} />

							{/* DG */}
							<PrivateRoute path="/DG" exact component={DGMyInterventions} group={g.DG} />
							<PrivateRoute path="/DG/managers" exact component={DGMyDA} group={g.DG} />
							<PrivateRoute path="/DG/calendar" component={DGCalendar} group={g.DG} />
							<PrivateRoute path="/DG/intervention/create" exact component={DGInterventionCreate} group={g.DG} />
							<PrivateRoute path="/DG/intervention/:id" key="read" exact component={DGInterventionRead} group={g.DG} />
							<PrivateRoute path="/DG/intervention/cancel/:id" key="cancel" exact component={DGInterventionRead} group={g.DG} />
							<PrivateRoute path="/DG/intervention/edit/:id" exact component={DGInterventionEdit} group={g.DG} />
							<PrivateRoute path="/DG/intervention/refuse/:id" key="refuse" exact component={DGInterventionRead} group={g.DG} />
							<PrivateRoute path="/DG/intervention/validate/:id" key="validate" exact component={DGInterventionRead} group={g.DG} />
							<PrivateRoute path="/DG/interventions" exact component={DGInterventions} group={g.DG} />
							<PrivateRoute path="/DG/participant/mission" component={ParticipantMission} group={g.DG} />
							<PrivateRoute path="/DG/profile" exact component={AccountProfile} group={g.DG} />
							<PrivateRoute path="/DG/mission/:id" exact component={MissionRead} group={g.DG} />

							{/* DA */}
							<PrivateRoute path="/DA" exact component={DAHome} group={g.DA} />
							<PrivateRoute path="/DA/intervention/create" exact component={DAInterventionCreate} group={g.DA} />
							<PrivateRoute path="/DA/intervention/edit/:id" exact component={DAInterventionEdit} group={g.DA} />
							<PrivateRoute path="/DA/intervention/cancel/:id" key="cancel" exact component={DAInterventionRead} group={g.DA} />
							<PrivateRoute path="/DA/intervention/validate/:id" key="read" exact component={DAInterventionRead} group={g.DA} />
							<PrivateRoute path="/DA/intervention/:id" key="read" exact component={DAInterventionRead} group={g.DA} />
							<PrivateRoute path="/DA/mission/:id" exact component={MissionRead} group={g.DA} />
							<PrivateRoute path="/DA/profile" exact component={AccountProfile} group={g.DA} />
							<PrivateRoute path="/DA/participant/mission" component={ParticipantMission} group={g.DA} />
							<PrivateRoute path="/DA/calendar" component={DACalendar} group={g.DA} />

							{/* DC*/}
							{/* <PrivateRoute path="/DC/interventions" exact component={DCMyInterventions} group={g.DC} /> */}
							<PrivateRoute path="/DC" exact component={AdminHome} group={g.DC} />
							<PrivateRoute path="/DC/animator" exact component={AdminAnimatorList} group={g.DC} />
							<PrivateRoute path="/DC/intervention/cancel/:id" key="cancel" exact component={DCInterventionRead} group={g.DC} />
							<PrivateRoute path="/DC/profile" exact component={AccountProfile} group={g.DC} />
							<PrivateRoute path="/DC/intervention" exact component={DCInterventions} group={g.DC} />
							<PrivateRoute path="/DC/intervention/create" exact component={DCInterventionCreate} group={g.DC} />
							<PrivateRoute path="/DC/intervention/refuse/:id" key="refuse" exact component={DCInterventionRead} group={g.DC} />
							<PrivateRoute path="/DC/intervention/edit/:id" exact component={DCInterventionEdit} group={g.DC} />
							<PrivateRoute path="/DC/intervention/validate/:id" key="validate" exact component={DCInterventionRead} group={g.DC} />
							<PrivateRoute path="/DC/intervention/:id" key="read" exact component={DCInterventionRead} group={g.DC} />

							<PrivateRoute path="/DC/mission/create" exact component={AdminMissionCreate} group={g.DC} />
							<PrivateRoute path="/DC/mission/create/from-intervention/:interventionId" exact component={MissionCreate} group={g.DC} />

							<PrivateRoute path="/DC/mission/edit/:id" exact component={MissionEdit} group={g.DC} />
							<PrivateRoute path="/DC/mission/cancel/:id" key="cancel" exact component={MissionRead} group={g.DC} />
							<PrivateRoute path="/DC/mission/closed/:id" exact component={MissionRead} group={g.DC} />
							<PrivateRoute path="/DC/mission/:id" key="read" exact component={MissionRead} group={g.DC} />
							<PrivateRoute path="/DC/calendar" exact component={AdminCalendar} group={g.DC} />
							<PrivateRoute path="/DC/participant/mission" exact component={ParticipantMission} group={g.DC} />
							<PrivateRoute path="/DC/mission" exact component={SuperAdminMission} group={g.DC} />

							{/* ADMIN */}
							<PrivateRoute path="/admin" exact component={AdminHome} group={g.Admin} />
							<PrivateRoute path="/admin/animator" exact component={AdminAnimatorList} group={g.Admin} />
							<PrivateRoute path="/admin/profile" exact component={AccountProfile} group={g.Admin} />

							<PrivateRoute path="/admin/intervention" exact component={AdminIntervention} group={g.Admin} />
							<PrivateRoute path="/admin/intervention/create" exact component={SuperAdminInterventionCreate} group={g.SuperAdmin} />

							<PrivateRoute path="/admin/intervention/cancel/:id" key="cancel" exact component={AdminInterventionRead} group={g.Admin} />
							<PrivateRoute path="/admin/intervention/edit/:id" exact component={AdminInterventionEdit} group={g.Admin} />
							<PrivateRoute path="/admin/intervention/refuse/:id" key="refuse" exact component={AdminInterventionRead} group={g.Admin} />
							<PrivateRoute path="/admin/intervention/validate/:id" key="validate" exact component={AdminInterventionRead} group={g.Admin} />
							<PrivateRoute path="/admin/intervention/:id" key="read" exact component={AdminInterventionRead} group={g.Admin} />
							{/* <PrivateRoute path="/admin/animator/create" exact component={AdminAnimatorCreate} group={g.Admin} /> */}
							{/* <PrivateRoute path="/admin/animator/:matricule" exact component={AccountAnimatorEdit} group={g.Admin} /> */}
							<PrivateRoute path="/admin/mission/create" exact component={AdminMissionCreate} group={g.Admin} />
							<PrivateRoute path="/admin/mission/create/from-intervention/:interventionId" exact component={MissionCreate} group={g.Admin} />
							<PrivateRoute path="/admin/mission/create/duplicate/:duplicateMissionId" exact component={MissionCreate} group={g.Admin} />

							<PrivateRoute path="/admin/mission/edit/:id" exact component={MissionEdit} group={g.Admin} />
							<PrivateRoute path="/admin/mission/cancel/:id" key="cancel" exact component={MissionRead} group={g.Admin} />
							<PrivateRoute path="/admin/mission/closed/:id" exact component={MissionRead} group={g.Admin} />
							<PrivateRoute path="/admin/mission/:id" key="read" exact component={MissionRead} group={g.Admin} />
							<PrivateRoute path="/admin/calendar" exact component={AdminCalendar} group={g.Admin} />
							<PrivateRoute path="/admin/participant/mission" exact component={ParticipantMission} group={g.Admin} />
							<PrivateRoute path="/admin/mission" exact component={SuperAdminMission} group={g.Admin} />

							{/* SUPER_ADMIN */}
							<PrivateRoute path="/admin/participant/create" exact component={ParticipantCreate} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/participant/edit/:matricule" exact component={ParticipantEdit} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/user" exact component={AccountList} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/user/edit/:matricule" exact component={AccountEdit} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/user/create" exact component={AccountCreate} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/settings" exact component={SuperAdminSettings} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/settings/place" exact component={SuperAdminSettingsPlace} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/settings/place/edit/:id" exact component={SuperAdminSettingsPlaceEdit} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/settings/participant" exact component={SuperAdminSettingsParticipant} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/settings/domain" exact component={SuperAdminSettingsDomain} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/settings/domain/edit/:id" exact component={SuperAdminSettingsDomainEdit} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/settings/formation-type/edit/:id" exact component={SuperAdminSettingsFormationEdit} group={g.SuperAdmin} />
							<PrivateRoute path="/admin/settings/formation-type" exact component={SuperAdminSettingsFormation} group={g.SuperAdmin} />
							<PrivateRoute path="/stats" exact component={StatisticsMission} group={g.SuperAdmin} />

							<PrivateRoute path="/changelog" exact component={PageChangeLog} group={Object.values(g)} />
							<Route component={PageNotFound} />

						</Switch>
					</DefaultLayout>
				</Route>
			</Switch>
		</Suspense>
	);
};
